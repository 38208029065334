.app {
    ion-button {
        font-size: 16px;
        font-weight: 700;
        text-transform: none;
        height: 3.5em;
        --border-radius: 5px;
        margin: 0;
    }

    @media (min-width: 1200px) {
        font-size: 18px;
    }
}

.navigation {
    &__search-icon {
        float: right;
        font-size: 24px;
        margin: 4px 20px;
    }
}
